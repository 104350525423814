import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const DistributeursPage = () => (
  <Layout>
    <Seo title="Distributeurs" />
    <div className="row">
      <div className="col-md-8 offset-md-2">
        <h1 className="mb-4 text-center">Où trouver nos produits?</h1>
        <img
          className="img-fluid"
          src="/img/210602_SF_2021_BaseLab_Map0.png"
          alt="Cartes des revendeurs BaseLab."
        />
        <div className="two-cols">
          <ul>
            <li>Alchimie Vape 1690 Villaz-St-Pierre</li>
            <li>Artvap 1800 Vevey</li>
            <li>Bar-a-Clope 1920 Martigny</li>
            <li>Chez Smoke Cigarette Electronique 2000 Neuchâtel</li>
            <li>Chez Smoke Cigarette Electronique 1530 Payerne</li>
            <li>City-Vape 3011 Bern</li>
            <li>Genesis Vape 2300 La Chaux-de-Fonds</li>
            <li>Green Smoking 2800 Delémont</li>
            <li>High Creek La Chaux 2300 La Chaux-de-Fonds</li>
            <li>High Creek Lausanne 1006 Lausanne</li>
            <li>High Creek Yverdon 1400 Yverdon-les-Bains</li>
            <li>High-Creek Sion 1950 Sion</li>
            <li>Jungle Vape 1610 Châtillens</li>
            <li>Kaya Shop Sàrl 1400 Yverdon</li>
            <li>La fabrick 1820 Montreux</li>
            <li>My Ohm'ies vapes distribution 1870 Monthey</li>
            <li>My Vape Shop 1510 Moudon</li>
            <li>MyVap's 1700 Fribourg</li>
            <li>Paboo-Vap 1400 Yverdon-les-Bains</li>
            <li>Résidence VapeShop 1530 Payerne</li>
            <li>Revercig 1201 Genève</li>
            <li>Rosa Vapers Sàrl 2206 Les Geneveys-Sur-Coffrane</li>
            <li>Teco Vape 2800 Delémont</li>
            <li>Sainte Vape 1095 Lutry</li>
            <li>Shifter Bulle 1630 Bulle</li>
            <li>Shifters Lausanne 1004 Lausanne</li>
            <li>Smoke Move Store 1201 Genève</li>
            <li>Smoke'n Vape 4127 Birsfelden</li>
            <li>Smoking Frogs 1470 Estavayer-le-Lac</li>
            <li>Soho’Vape 2013 Colombier</li>
            <li>Sweetch 1260 Nyon</li>
            <li>Ultimate Vapers 1630 Bulle</li>
            <li>Vap-E Shop 1800 Vevey</li>
            <li>Vape-R Shop 1800 Vevey</li>
            <li>Vapecloud.ch 1754 Avry-Sur-Matran</li>
            <li>VapLife 1673 Gillarens</li>
            <li>Vapored 1202 Genève</li>
            <li>Vapothèque 1860 Aigle</li>
            <li>Vapsmoke 6006 Luzern</li>
            <li>Vavapo 1211 Genève</li>
            <li>VeryVapeTrip 1868 Collombey</li>
            <li>Viper Smoke 1618 Châtel-St-Denis</li>
            <li>Viper Smoke 1680 Romont</li>
            <li>Wevappy 1212 Grand-Lancy</li>
            <li>World of Vape 3011 Bern</li>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
)

export default DistributeursPage
